// ==============================
// CUSTOM SCRIPT START
// ==============================

// Info pane reveal on intro screen
$(".nav-info").click(function(){
    $(this).toggleClass("info-open");
});

// hide loader on load
window.onload = function() { 
	document.getElementById("hideAll").style.cssText = "display:none;opacity:0;"; 
}

// ==============================
// GSAP ANIMATION START
// ==============================

function init(){
	fragmentAnimations(); 
};


Reveal.addEventListener( 'strapline-slide-animate', function() {
	// TODO: Sprinkle magic
	TweenMax.from($('.location-background'), 120, { scaleX:2.4, scaleY:2.4, delay:1.25 });
}, false );
// background slow zoom animation function


// some tools to help us deal with the iframes on
// locally saved versions of presentations
var iframeTools = {
  thisInterval: '',
  beginKeepingFocussed: function() {
    this.thisInterval = setInterval(function() {
      // this is the way to push the focus back to the main window
      $(window).focus();
    }, 250);
  },
  stopKeepingFocussed: function() {
    clearInterval(this.thisInterval);
  }
}


// fragment animations for copy
function fragmentAnimations() {
	Reveal.addEventListener( 'fragmentshown', function( event ) {
		// IN
		$(event.fragment).each(function() {
			if ( $(this).children('.solution-text').hasClass('without-image') ) {
				$( this ).addClass('fadeInUp');
				$( this ).siblings('.fragment').removeClass('fadeInUp');
				// swap solution text with fragment text IN
				if( $( this ).siblings('.flex-outer').children().hasClass('solution-text') ){
					$( this ).siblings('.flex-outer').children('.solution-text').addClass('fadeOutUp animated');
				}
			} 
			// SPLASH
			if ( $(this).hasClass('splash-brand') ) {
				$('#rl-logo').addClass('fadeOutDown');
				// $( this ).addClass('show-brand');
				$( this ).children('.splash-title').addClass('fadeInDownBig');
				$( this ).children('.splash-name').addClass('fadeInDownBig');
				$( this ).children('.splash-date').addClass('fadeInDownBig');
			}
	    });
		// OUT
		var previousFragment = $(event.fragment.previousElementSibling);
		if ( previousFragment.hasClass('fragment') ) {
			previousFragment.each(function() {
				if ( $(this).children('.solution-text').hasClass('without-image') ) {
					$( this ).addClass('fadeOutUp');
				}
		    });		
		}
	});

	// COMING BACK IN FROM Solution
	Reveal.addEventListener( 'fragmenthidden', function( event ) {
		var previousFragment = $(event.fragment.previousElementSibling);

		$(event.fragment).each(function() {
			if ( $(this).children('.solution-text').hasClass('without-image') ) {
				$( this ).removeClass('fadeInUp');
				$( this ).addClass('fadeOutUp');
				// swap solution text with fragment text IN
				if( $( this ).siblings('.flex-outer').children().hasClass('solution-text') ){
					$( this ).siblings('.flex-outer').children('.solution-text').removeClass('fadeOutUp')
					$( this ).siblings('.flex-outer').children('.solution-text').addClass('fadeInDown');
				}
			}
			// SPLASH
	    	if ( $(this).hasClass('splash-brand') ) {
	    		$('#rl-logo').removeClass('fadeOutDown');
	    		$( this ).children('.splash-title').removeClass('fadeInDownBig');
				$( this ).children('.splash-name').removeClass('fadeInDownBig');
				$( this ).children('.splash-date').removeClass('fadeInDownBig');
			}
	    });

		previousFragment.each(function() {
			if ( $(this).children('.solution-text').hasClass('without-image') ) {
				$( this ).removeClass('fadeOutUp');
				$( this ).addClass('fadeInUp');
			}
	    });


	});

	// reset FRAGMENTS on slide change
	Reveal.addEventListener( 'slidechanged', function( event ) {
    // does this slide contain an iframe?
		if($(event.currentSlide).find('iframe').length > 0) {
      // if so then activate the function to keep focus
      iframeTools.beginKeepingFocussed();
      // also check to see if it needs reloading, if so then reload it
      // (but don't do this twice in one session)
      if($(event.currentSlide).find('iframe:not(.reloaded)').length > 0 && $(event.currentSlide).find('iframe:not(.reloaded)').attr('src').indexOf('reload') > 0) {
        var refreshsrc = $(event.currentSlide).find('iframe').attr('src');
        $(event.currentSlide).find('iframe').attr('src', refreshsrc);
        $(event.currentSlide).find('iframe').addClass('reloaded');
      }
		} else {
      // if we've not got an iframe then lets stop the focus stealer to save cpu
      iframeTools.stopKeepingFocussed();
    }

		// IF COMING BACK
		if ( $( event.previousSlide ).hasClass('cs-approach')) {
			$( event.previousSlide ).find('.fragment').removeClass('fadeInUp');
		} 
		// IF COME BACK AND WANT TO GO FORTH AGAIN
		if ( $( event.currentSlide ).hasClass('cs-challenge') && $( event.previousSlide ).hasClass('cs-approach')) {
			$( event.previousSlide ).find('.fragment').removeClass('fadeOutUp');
		}
		// IF COMING BACK
		if ( $( event.previousSlide ).hasClass('cs-solution')) {
			$( event.previousSlide ).find('.fragment').removeClass('fadeInUp');
		} 
		// IF COME BACK AND WANT TO GO FORTH AGAIN
		if ( $( event.currentSlide ).hasClass('cs-approach') && $( event.previousSlide ).hasClass('cs-solution')) {
			$( event.previousSlide ).find('.fragment').removeClass('fadeOutUp');
		}
	});
}

$('.arrow-wrap').click(function() {
  Reveal.next();
});

init();


